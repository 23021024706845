import styled from "@emotion/styled";
import { Button, Input, Table, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { WmsBatchPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import useInventoriesGetQuery from "api/inventories/useInventoriesGetQuery";
import useInventoryMutation from "api/inventories/useInventoriesMutationQuery";
import { LocationAutoComplete } from "components/common/autoComplete/location/location-autoComplate";
import { InventoryBarcodeInput } from "components/common/barcode/inventory/inventoriesBarcodeInput";


import { HeaderSubTitle } from "components/common/standard/SubTitle";
import { HeaderTitle } from "components/common/standard/Title";
import { useModal } from "context/ModalStackManager";
import { customNotification } from "utils/notificationShow";
import { BulkAllFormRow } from "./BulkAllFormRow";
import { inventoryUserDataProps } from "./OutgoingAllForm";

export interface BulkFormItemProps {
    formData: {
        lotId: number;
        lotName?: string;
        itemCode?: string;
        itemName?: string;
        spec?: string;
        quantity?: string;
        locationCode?: string;
    }
    seq: number;
    onChange: (index: number, quantity: string) => void;
    onDelete: (index: number) => void;
}

interface BulkFormProps extends WmsBatchPostRequest {
    barcodeInput: string | undefined;
    lotId: string | undefined
    items: {
        lotId: number;
        lotName?: string;
        itemCode?: string;
        itemName?: string;
        spec?: string;
        quantity?: string;
        locationCode?: string;
    }[]
}


export const TransferAllForm = (props: inventoryUserDataProps) => {
    const { userData } = props;
    const locationCode = userData?.locationCode

    const form = useForm<Partial<BulkFormProps>>({
        initialValues: {
            barcodeInput: undefined,
            lotId: undefined,
            fromLocationCode: locationCode ?? undefined,
            toLocationCode: undefined,
            items: [],
            additional: {}
        }
    });

    const { mutate: createMutate } = useInventoryMutation("transferAll");

    // const barcodeRef = useRef<HTMLInputElement>();
    const { closeModal } = useModal();

    const onSubmit = () => {
        createMutate(
            {
                wmsBatchPostRequest: {
                    fromLocationCode: form.values.fromLocationCode as string,
                    toLocationCode: form.values.toLocationCode as string,
                    items: form.values.items?.map(item => ({
                        lotId: Number(item.lotId),
                        quantity: item.quantity
                    })),
                    additional: { ...form.values.additional }
                },
            },
            {
                onSuccess: (res: { status: number }) => {
                    closeModal(form.values);
                    if (res.status === 200) {
                        alert("벌크 이동에 성공하였습니다.");
                    } else {
                        alert("벌크 이동에 실패하였습니다.");
                    }
                },
            })
    }

    useInventoriesGetQuery(form.values.lotId && form.values.fromLocationCode ? {
        query: {
            $and: [
                {
                    lotId: { $eq: form.values.lotId }
                },
                {
                    locationCode: { $eq: form.values.fromLocationCode }
                },
                {
                    quantity: { $gt: 0 },
                }
            ]
        }
    } : undefined
        , {
            onSuccess: (response) => {
                const lotData = response.rows?.[0];
                if (!lotData) {
                    customNotification.error({ message: '바코드에 해당하는 로트가 존재하지 않습니다.' })
                    form.setFieldValue('barcodeInput', '')
                    form.setFieldValue('lotId', '')
                    return;
                }
                const { lot, itemCode, itemName, spec, quantity, lotId } = lotData;
                form.insertListItem('items', { lotId, lotName: lot?.name, itemCode, itemName, spec, quantity });
                form.setFieldValue('barcodeInput', '')
                form.setFieldValue('lotId', '')
            }
        })

    const handleRowChange = (index: number, quantity: string) => {
        form.setFieldValue(`items.${index}.quantity`, quantity);
    }

    const handleRowDelete = (index: number) => {
        form.removeListItem('items', index)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {

            if (!form.values.fromLocationCode) {
                customNotification.error({ message: '출고 로케이션을 선택해주세요.' })
                return;
            }

            const hasExistingLot =
                form.values.items?.find(item => Number(item.lotId) === Number(form.values.barcodeInput));

            if (hasExistingLot) {
                customNotification.error({ message: '이미 추가된 LOT 입니다.' })
                form.setFieldValue('barcodeInput', '')
                form.setFieldValue('lotId', '')
                return;
            }
            form.setFieldValue("lotId", form.values.barcodeInput);
        }
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {

        if (!form.values.fromLocationCode) {
            customNotification.error({ message: '출고 로케이션을 선택해주세요.' })
            return;
        }

        const hasExistingLot =
            form.values.items?.find(item => Number(item.lotId) === Number(form.values.barcodeInput));

        if (hasExistingLot) {
            customNotification.error({ message: '이미 추가된 LOT 입니다.' })
            form.setFieldValue('barcodeInput', '')
            form.setFieldValue('lotId', '')
            return;
        }
        const inputValue = event.target.value;
        form.setFieldValue("lotId", inputValue);
    };

    return (<TransferAllFormContainer>
        <TitleHeader>
            <HeaderTitle>바코드 LOT 입력</HeaderTitle>
            <HeaderSubTitle>바코드를 스캔하여 자동으로 데이터를 입력합니다. <br /> 벌크 이동 버튼을 클릭하여 이동을 완료합니다.</HeaderSubTitle>
        </TitleHeader>
        <BarcodeInputContainer>
            <LocationContainer>
                {locationCode !== null ? (
                    <>
                        <LocationAutoComplete
                            size="xl"
                            dropdownPosition="bottom"
                            defaultValue={locationCode}
                            label="출고 로케이션"
                            {...form.getInputProps("fromLocationCode")}
                        />
                        <LocationAutoComplete
                            size="xl"
                            data-autofocus
                            dropdownPosition="bottom"
                            label="입고 로케이션"
                            {...form.getInputProps("toLocationCode")}
                        />
                    </>
                ) : locationCode === null ? (
                    <>
                        <LocationAutoComplete
                            size="xl"
                            data-autofocus
                            dropdownPosition="bottom"
                            defaultValue={locationCode}
                            label="출고 로케이션"
                            {...form.getInputProps("fromLocationCode")}
                        />
                        <LocationAutoComplete
                            size="xl"
                            dropdownPosition="bottom"
                            label="입고 로케이션"
                            {...form.getInputProps("toLocationCode")}
                        />
                    </>
                ) : ''}
            </LocationContainer>
            <Input.Wrapper label="바코드 입력 필드" size="xl">
                <InventoryBarcodeInput
                    handleBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    {...form.getInputProps("barcodeInput")}
                />
            </Input.Wrapper>
        </BarcodeInputContainer>
        <TableWrapper>
            <Table>
                <thead>
                    <tr>
                        <Th width={7}><Text size="2.5rem">시퀀스</Text></Th>
                        <Th width={14}><Text size="2.5rem">LOT ID</Text></Th>
                        <Th width={14}><Text size="2.5rem">LOT 명</Text></Th>
                        <Th width={14}><Text size="2.5rem">품목 코드</Text></Th>
                        <Th width={14}><Text size="2.5rem">품목명</Text></Th>
                        <Th width={14}><Text size="2.5rem">규격</Text></Th>
                        <Th width={14}><Text size="2.5rem">수량</Text></Th>
                        <Th width={7}>&nbsp;</Th>
                    </tr>
                </thead>
                <tbody>
                    {
                        form.values.items?.map((formData, index) =>
                            <BulkAllFormRow formData={formData} seq={index} key={formData.lotId + index} onChange={handleRowChange} onDelete={handleRowDelete} />
                        )
                    }
                    {
                        form.values.items && form.values.items.length === 0 &&
                        <tr>
                            <Td colSpan={8} width={100} style={{ textAlign: "center", fontSize: "3rem" }}>바코드를 스캔하여 LOT를 등록하세요.</Td>
                        </tr>
                    }
                </tbody>
            </Table>
        </TableWrapper>
        <ButtonBox>
            <Button size="xl" color="gray" onClick={closeModal}>
                취소
            </Button>
            <Button size="xl" onClick={onSubmit}>벌크 이동</Button>
        </ButtonBox>
    </TransferAllFormContainer>)
}

const TransferAllFormContainer = styled.div`
    display: flex;
    width: 100rem;
    min-height: 40rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`

const TitleHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`

const BarcodeInputContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 8px;
    align-items: flex-end;
    gap: 10px;
    flex-shrink: 0;
`

const LocationContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
`

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
`

const ButtonBox = styled.div`
    display: flex;
    height: 44px;
    padding: 8px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
`

const Th = styled.th<{ width?: number }>`
    width: ${(props) => (props.width ? `${props.width}%` : "auto")};
    padding: 7px 10px;
    align-items: flex-start;
`

export const Td = styled.td<{ width?: number }>`
    width: ${(props) => (props.width ? `${props.width}%` : "auto")};
    padding: 7px 10px;
    align-items: flex-start;
`