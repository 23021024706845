import { Flex } from '@mantine/core'; // 필요한 컴포넌트를 임포트하세요.
import { InventoriesGet200Response, ProductionPlansGet200ResponseRowsInnerWorksInner } from '@sizlcorp/sizl-api-document/dist/models';
import { BaseText } from 'components/common/Text/baseText';
import { BaseTitle } from 'components/common/title/BaseTitle';
import dayjs from 'dayjs';
import { setToLocaleString } from 'utils/unitMark';
import { WorkTable } from '../../../common/table/workTable';


const WorkInventories = Object.assign({}, {
    Text: BaseText,
    Title: BaseTitle,
    Table: WorkTable
});

interface WorkInventoriesComponentProps {
    workData: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined
    workInventoriesData: InventoriesGet200Response | undefined
}

export const WorkInventoriesComponent = (params: WorkInventoriesComponentProps) => {

    const { workData, workInventoriesData } = params

    return (
        <>
            <Flex w={"100%"} direction={'column'} p={10} gap={10}>
                <Flex justify={"space-between"}>
                    <WorkInventories.Title>{"원부자재 로케이션"}</WorkInventories.Title>
                    <Flex wrap={"wrap"}>
                        <WorkInventories.Text>
                            [{workData?.currentRoutingOutsourceData?.fromLocationCode ?? workData?.locationSetting?.fromLocation?.code}]
                        </WorkInventories.Text>
                        <WorkInventories.Text>
                            {workData?.currentRoutingOutsourceData?.fromLocationName ?? workData?.locationSetting?.fromLocation?.name}
                        </WorkInventories.Text>
                    </Flex>
                </Flex>
                <Flex justify={"space-between"}>
                    <WorkInventories.Title>{"생산 로케이션"}</WorkInventories.Title>
                    <Flex wrap={"wrap"}>
                        <WorkInventories.Text>
                            [{workData?.currentRoutingOutsourceData?.toLocationCode ?? workData?.locationSetting?.toLocation?.code}]
                        </WorkInventories.Text>
                        <WorkInventories.Text>
                            {workData?.currentRoutingOutsourceData?.toLocationName ?? workData?.locationSetting?.toLocation?.name}
                        </WorkInventories.Text>
                    </Flex>
                </Flex>
            </Flex>
            <WorkInventories.Table
                columns={["품목코드", "로트명", "로트유효기한", "잔량"]}
                flex={[2, 2, 1, 1]}
                data={
                    workInventoriesData?.rows?.map((row) => {
                        return [
                            row.itemCode,
                            row.lot?.name ?? "-",
                            row.lot?.expiration
                                ? dayjs(row.lot?.expiration).format("YYYY-MM-DD")
                                : "-",
                            <div style={{ textAlign: "right" }}>{(setToLocaleString(row.quantity ?? '') + " " + row.unitText)}</div>,

                        ];
                    }) as string[][]
                }
            />
        </>
    );
};

export default WorkInventoriesComponent;