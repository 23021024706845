import { Button, Flex, Text } from '@mantine/core';
import React from 'react';
import { BaseButton } from '../button/baseButton';

interface ConfirmProps {
    message: string | JSX.Element;
    yesMessage?: string;
    noMessage?: string;
    yesMessage2?: string;
    yesCallback: () => void;
    noCallback: () => void;
    commonCallback: () => void;
    yesCallback2?: () => void;
}

export const Confirm: React.FC<ConfirmProps> = ({ message, yesMessage, noMessage, yesMessage2, yesCallback, noCallback, commonCallback, yesCallback2 }) => {

    const callback = async (callbackFn: () => void) => {
        await commonCallback();
        await callbackFn();
    }

    return (
        <Flex w="60rem" mih="10rem" direction="column" gap="sm" justify="space-between">
            <Text style={{ wordWrap: "break-word", overflowWrap: 'break-word', whiteSpace: "pre-wrap" }} size="3rem">{message}</Text>
            <Flex gap="md" justify="flex-end">
                <BaseButton color="red" size='xl' onClick={() => callback(noCallback)}>
                    {noMessage ? noMessage : "아니오"}
                </BaseButton>
                <Button color="green" size='xl' onClick={() => callback(yesCallback)}>
                    {yesMessage ? yesMessage : "예"}
                </Button>
                {yesCallback2 &&
                    <Button color="blue" size='xl' onClick={() => callback(yesCallback2)}>
                        {yesMessage2 ? yesMessage2 : "예(이동)"}
                    </Button>
                }
            </Flex>
        </Flex>
    );
};
