import { Button, Flex, Loader, SegmentedControl, Text, Title } from "@mantine/core";
import { InspectionsGet200ResponseRowsInnerRecordInnerInner, SpecificationsGet200ResponseRowsInnerCriteriaInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconFileDownload } from "@tabler/icons-react";
import useInspectionMutation from "api/inspections/useInspectionMutationQuery";
import useSpcInspectionsGetQuery from "api/inspections/useInspectionsGetQuery";
import useSpcGetQuery from "api/inspections/useSpecificationsGetQuery";
import { useLocalStorage } from "context/LocalStorageProvider";
import { useModal } from "context/ModalStackManager";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { customNotification } from "utils/notificationShow";
import { WorkInputFormProps } from "../material/RawMaterialForm";


interface InspectionSpecificationsProps extends InspectionsGet200ResponseRowsInnerRecordInnerInner {
  createdAt: string | undefined;
  isSaved: boolean;
}

type InspectionQueryType = {
  $and: Array<{
    itemCode?: { $eq: any; };
    productionPlanId?: { $eq: any; };
    lotId?: { $eq: any; };
  }>
}

export const InspectForm = ({ workData, lotId }: WorkInputFormProps) => {
  const { closeModal } = useModal();
  const { data: specifications } = useSpcGetQuery({
    query: { $and: [{ itemCode: { $eq: workData?.item?.code } }] },
  });

  const inspectionQuery: InspectionQueryType = {
    $and: [
      { itemCode: { $eq: workData?.item?.code } },
      {
        productionPlanId: {
          $eq
            : workData?.productionPlanId
        }
      },
    ]
  }

  if (lotId) {
    inspectionQuery.$and.push({ lotId: { $eq: lotId } })
  }

  const { data: inspections } = useSpcInspectionsGetQuery({
    query: inspectionQuery,
    populate: ['user', 'lot']
  });

  const [records, setRecords] = useState<
    Array<Array<InspectionSpecificationsProps>>
  >([]);

  const { mutate: postMutate, isLoading : postLoading } = useInspectionMutation("create");
  const { mutate: updateMutate, isLoading : updateLoading } = useInspectionMutation("update");
  const { mutate: submitMutate, isLoading : submitLoading } = useInspectionMutation("submit");

  const specificationData = specifications?.data?.rows?.[0];

  const { managementRole } = useLocalStorage();

  const onSave = () => {
    if ((inspections?.data?.rows?.length as number) < 1) {
      postMutate(
        {
          inspectionsGetRequest: {
            itemCode: workData?.item?.code,
            specificationId: specificationData?.id!,
            productionPlanId: workData?.productionPlanId,
            equipmentCode: workData?.equipmentCode,
            lotId: lotId
          },
        },
        {
          onSuccess: (res) => {
            submitMutate({
              inspectionId: res.data.id,
              inspectionsInspectionIdSubmitPostRequest: {
                record: records.map((record: InspectionsGet200ResponseRowsInnerRecordInnerInner[]) => {
                  return record.map((data: InspectionsGet200ResponseRowsInnerRecordInnerInner) => {
                    return {
                      ...data,
                      isSaved: data.value === undefined ? false : true,
                      creatorUserId: localStorage?.getItem("userId") as string,
                      // creatorUserCode: data.creatorUserName //localStorage?.getItem("name") as string,
                    }
                  })
                }),
              },
            });
            customNotification.success({
              message: "초중종품 검사가 성공적으로 되었습니다.",
            });
            closeModal({});
          },
        }
      );
    } else {
      updateMutate(
        {
          inspectionId: inspections?.data?.rows?.[0].id as number,
          inspectionsGetRequest: {
            specificationId: specificationData?.id!,
            itemCode: workData?.item?.code,
            specificationData: inspections?.data?.rows?.[0].specificationData,
            record: records, //inspections?.data?.rows?.[0].record,
            equipmentCode: workData?.equipmentCode
          },
        },
        {
          onSuccess: (res) => {
            submitMutate({
              inspectionId: res.data.id,
              inspectionsInspectionIdSubmitPostRequest: {
                record: records.map((record: InspectionsGet200ResponseRowsInnerRecordInnerInner[]) => {
                  return record.map((data: InspectionsGet200ResponseRowsInnerRecordInnerInner) => {
                    return {
                      ...data,
                      isSaved: data.value === undefined ? false : true,
                      // name이 같은 경우에는 데이터를 수정했다는 뜻이므로, creatorUserId를 변경하지 않는다.
                      creatorUserId: data.creatorUserName === localStorage.getItem("name") ? localStorage?.getItem("userId") as string : data.creatorUserId,
                    }
                  })
                }),
              },
            });
            customNotification.success({
              message: "초중종품 검사가 성공적으로 되었습니다.",
            });
            closeModal({});
          },
        }
      );
    }
  };

  useEffect(() => {

    if ((inspections?.data?.rows?.length as number) > 0) {

      setRecords(
        inspections?.data?.rows?.[0]
          .record as InspectionSpecificationsProps[][]
      );

    } else {
      const newRecords = specificationData?.criteria?.map((criteria: SpecificationsGet200ResponseRowsInnerCriteriaInner) =>
        Array.from({ length: criteria.sampleNum ?? 0 }).map((_, i) => ({
          value: undefined,
          isPassed: undefined,
          createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          creatorUserName: localStorage?.getItem("name")!,
          isSaved: false
        }))
      ) ?? [];

      setRecords(newRecords);
    }
  }, [inspections, specificationData?.criteria]);

  const filteredSpecificationData = specificationData?.criteria?.filter((criteria, index) => {
    if (index < (inspections?.data?.rows?.[0]?.record?.length as number)) {
      return criteria
    }
  })
  if(postLoading || updateLoading || submitLoading) return <Flex w="100%" h="100%" justify="center" align="center"><Loader size="30rem" /></Flex>
  return (
    <Flex direction="column" w="60rem" h="auto" gap="2rem">
      {((filteredSpecificationData?.length as number) > 0 ? filteredSpecificationData : specificationData?.criteria)?.map((criteria: SpecificationsGet200ResponseRowsInnerCriteriaInner, index: number) => {
        return (
          <Flex direction="column" gap="2rem" key={criteria.name + index} mt="1rem">
            <Title>
              포인트{index + 1}. {criteria.name}
            </Title>
            <Flex direction='column' gap='2rem'>
              {Array.from({ length: criteria.sampleNum ?? 0 }).map((_, i) => {
                return (
                  // key로 index를 사용하는 것이 현재로서는 최선일 듯.
                  <Flex direction="column" key={i} gap="1rem">
                    <Flex justify="center" align="center">
                      <Text size="20px" px="xl">{i === 0 ? "초물" : i === 1 ? "중물" : i === 2 ? "종물" : ""}</Text>
                      <SegmentedControl
                        disabled={managementRole !== 'UPDATE' && records?.[index]?.[i]?.isSaved}
                        key={i}
                        size="xl"
                        radius='1.5rem'
                        w="100%"
                        color="blue"
                        value={
                          records?.[index]?.[i]?.value === undefined
                            ? "응답없음" : records?.[index]?.[i]?.isPassed ? "합격" : "불합격"
                        }
                        onChange={(value) => {
                          setRecords((prevRecords) => {
                            const newRecords = [...prevRecords];
                            newRecords[index][i].creatorUserName = localStorage?.getItem("name")!;
                            newRecords[index][i].createdAt = dayjs().format("YYYY-MM-DD HH:mm:ss");
                            newRecords[index][i].value =
                              value === "응답없음" ? undefined : value === "합격";
                            newRecords[index][i].isPassed =
                              value === "응답없음" ? undefined : value === "합격";
                            return newRecords;
                          });
                        }}
                        data={[
                          { value: "응답없음", label: "응답없음" },
                          { value: "합격", label: "합격" },
                          { value: "불합격", label: "불합격" },
                        ]}
                      />
                    </Flex>
                    {records?.[index]?.[i]?.isSaved && (
                      <Flex direction="column" gap="sm">
                        <Text>
                          검사자 : {records?.[index]?.[i]?.creatorUserName}
                        </Text>
                        <Text>
                          검사날짜 : {records?.[index]?.[i]?.createdAt}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        );
      })}
      <Button
        w="100%"
        size="lg"
        fz="xl"
        leftIcon={<IconFileDownload />}
        onClick={() => {
          onSave();
        }}
      >
        저장
      </Button>
    </Flex>
  );
};
