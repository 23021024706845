import { Button, Text, TextInput } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { RawMaterialInputAllFormProps, Td } from "./RawMaterialInputAllForm";

export const RawMaterialFormRow: React.FC<RawMaterialInputAllFormProps> = ({ formData, seq, onChange, onDelete }) => {
    const { lotId, itemCode, itemName, lotName, lotExpireDate, locationCode, quantity } = formData;

    return <tr>
        <Td><Text align="right" size="1.5rem">{seq + 1}</Text></Td>
        <Td><Text size="1.5rem">{itemCode}</Text></Td>
        <Td><Text size="1.5rem">{itemName}</Text></Td>
        <Td><Text size="1.5rem">{lotName}</Text></Td>
        <Td><Text size="1.5rem">{lotExpireDate}</Text></Td>
        <Td><Text size="1.5rem">{locationCode}</Text></Td>
        <Td><TextInput size="xl" type="number" value={quantity} onChange={(e) => onChange(seq, e.target.value)} /></Td>
        <Td><Button leftIcon={<IconTrash />} color="red" variant="white" onClick={() => onDelete(seq)}>삭제</Button></Td>
    </tr>
}
