import BigNumber from "bignumber.js";

export const calculateArraySum = (data: string[]) => {
  return data?.reduce((acc: any, value: any) => {
    return BigNumber(acc)
      .plus(value ?? 0)
      .toString();
  }, "0");
};

export const plusBigNumber = (num1: string | number | BigNumber, num2: number | string | BigNumber): string => {
  const bigNum1 = new BigNumber(num1);
  const bigNum2 = new BigNumber(num2);
  return bigNum1.plus(bigNum2).toString();
} 