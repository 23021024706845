import { ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { INVENTORIES_KEY } from "api/inventories/useInventoriesQuery";
import { WORK_LOG_GET_KEY } from "api/logs/useWorkLogsGetQuery";
import { WORK_KEY } from "api/work/useWorkQuery";
import { ProductionInstance } from "instance/axios";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";

//삭제
const fetchWorkLogsWorkLogIdCancelDefectPut = ({
    workLogId,
}: ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest) =>
    ProductionInstance.workLogsWorkLogIdCancelDefectPut({
        workLogId,
    });

export const useWorkLogsWorkLogIdCancelDefectPut = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({
            workLogId,
        }: ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest) =>
            fetchWorkLogsWorkLogIdCancelDefectPut({ workLogId }),
        {
            onSuccess: () => {
                customNotification.success({
                    message: "불량이 성공적으로 취소되었습니다.",
                });
                queryClient.invalidateQueries(WORK_KEY)
                queryClient.invalidateQueries(WORK_LOG_GET_KEY);
                queryClient.invalidateQueries(INVENTORIES_KEY);
            },
        }
    );
};
export default useWorkLogsWorkLogIdCancelDefectPut;
