import { DefaultApiWmsFlushPutRequest, DefaultApiWorksWorkIdPutRequest, ProductionActionApiEquipmentsCorrectionPutRequest, ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest, ProductionActionApiWorksWorkIdDefectPutRequest, ProductionActionApiWorksWorkIdPerformancePutRequest, ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest, ProductionTimeTrackingActionApiWorksWorkIdTrackingPausePostRequest, ProductionTimeTrackingActionApiWorksWorkIdTrackingResumePostRequest, ProductionTimeTrackingActionApiWorksWorkIdTrackingStartPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { mutateDefect } from "api/defects/useDefectsQuery";
import { equipmentCorrection } from "api/equipmentCorrection/useEquipmentCorrection";
import { EQUIPMENTS_KEY } from "api/equipmentCount/useEquipmentQuery";
import { mutateFlush } from "api/flush/useFlushQuery";
import { workLogs } from "api/input/useCancelInput";
import { INVENTORIES_KEY } from "api/inventories/useInventoriesQuery";
import { WORK_LOGS_KEY } from "api/logs/useWorksLogsQuery";
import { WORK_KEY, mutateWorks } from "api/work/useWorkQuery";
import { mutatePerformance } from "api/workPerformance/useWorkPerformanceQuery";
import trackingActions from "api/workTracking/useWorkTarckingQuery";
import { useMutation, useQueryClient } from "react-query";
import { customNotification } from "utils/notificationShow";

export const usePutWorkData = () => {

    const queryClient = useQueryClient();

    const { mutate: performanceMutate, mutateAsync: performanceMutateAsync, isLoading : performanceLoading } = useMutation(
        (params: ProductionActionApiWorksWorkIdPerformancePutRequest) =>
            mutatePerformance.update(params).mutationFn(undefined),

        {
            onSuccess: () => {
                queryClient.invalidateQueries(WORK_KEY);
                queryClient.invalidateQueries(INVENTORIES_KEY);
                queryClient.invalidateQueries(WORK_LOGS_KEY);
                queryClient.invalidateQueries(EQUIPMENTS_KEY);
                customNotification.success({ message: '실적 저장에 성공 했습니다.' })
            },
        }
    )

    const { mutate: defectMutate, mutateAsync: defectMutateAsync, isLoading : defectLoading } = useMutation(
        (params: ProductionActionApiWorksWorkIdDefectPutRequest) =>
            mutateDefect.worksWorkIdDefectPut(params).mutationFn(undefined),
        {
            onSuccess: () => {
                // Reset the queries to refresh the data
                customNotification.success({ message: '불량 저장에 성공했습니다.' });
                queryClient.invalidateQueries(WORK_KEY);
                queryClient.resetQueries(WORK_KEY);
                queryClient.invalidateQueries(EQUIPMENTS_KEY);
            },
            onError: (error: any) => {
                customNotification.error({ message: error?.response?.data?.message ?? '불량 저장에 실패했습니다.' });
            }
        }
    );

    const { mutate: settingMutate, isLoading : settingLoading } = useMutation(
        (params: DefaultApiWorksWorkIdPutRequest) =>
            mutateWorks.update(params).mutationFn(undefined),
        {
            onSuccess: () => {
                customNotification.success({ message: '지정된 설정으로 저장하였습니다.' })
                queryClient.invalidateQueries(WORK_KEY);
            },
            onError: (error: any) => {
                customNotification.error({ message: error?.response?.data?.message ?? '지정된 설정으로 저장에 실패 했습니다.' })
            }
        }
    )

    const { mutate: flushMutate, isLoading : flushLoading } = useMutation(
        (params: DefaultApiWmsFlushPutRequest) =>
            mutateFlush.update(params).mutationFn(undefined),
        {
            onSuccess: () => {
                customNotification.success({ message: '초기화에 성공 했습니다.' })
                queryClient.invalidateQueries(INVENTORIES_KEY);
            },
            onError: (error: any) => {
                customNotification.error({ message: error?.response?.data?.message ?? '초기화에 실패 했습니다.' })
            }
        }
    )

    const { mutate: startMutate, isLoading : startLoading } = useMutation(
        (params: ProductionTimeTrackingActionApiWorksWorkIdTrackingStartPostRequest) =>
            trackingActions.start(params).mutationFn(undefined), {
        onSuccess: () => {
            queryClient.invalidateQueries(WORK_KEY);
            queryClient.invalidateQueries(WORK_LOGS_KEY);
            customNotification.success({ message: '시작에 성공 했습니다.' })
        },
        onError: (error: any) => {
            customNotification.error({ message: error?.response?.data?.message ?? '시작에 실패 했습니다.' })
        }
    })

    const { mutate: pauseMutate, isLoading : pauseLoading } = useMutation(
        (params: ProductionTimeTrackingActionApiWorksWorkIdTrackingPausePostRequest) =>
            trackingActions.pause(params).mutationFn(undefined), {
        onSuccess: () => {
            queryClient.invalidateQueries(WORK_KEY);
            queryClient.invalidateQueries(WORK_LOGS_KEY);
            customNotification.success({ message: '일시정지에 성공 했습니다.' })
        },
        onError: (error: any) => {
            customNotification.error({ message: error?.response?.data?.message ?? '일시정지에 실패 했습니다.' })
        },
    })

    const { mutate: endMutate, isLoading : endLoading } = useMutation(
        (params: ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest) =>
            trackingActions.end(params).mutationFn(params as ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest | any), {
        onSuccess: () => {
            queryClient.invalidateQueries(WORK_KEY);
            queryClient.invalidateQueries(WORK_LOGS_KEY);
            customNotification.success({ message: '종료에 성공 했습니다.' })
        },
        onError: (error: any) => {
            customNotification.error({ message: error?.response?.data?.message ?? '종료에 실패 했습니다.' })
        }
    })

    const { mutate: resumeMutate, isLoading : resumeLoading } = useMutation(
        (params: ProductionTimeTrackingActionApiWorksWorkIdTrackingResumePostRequest) =>
            trackingActions.resume(params).mutationFn(undefined), {
        onSuccess: () => {
            queryClient.invalidateQueries(WORK_KEY);
            queryClient.invalidateQueries(WORK_LOGS_KEY);
            customNotification.success({ message: '작업재개에 성공 했습니다.' })
        },
        onError: (error: any) => {
            customNotification.error({ message: error?.response?.data?.message ?? '작업재개에 실패 했습니다.' })
        }
    })

    const { mutate: correctMutate, isLoading : correctLoading } = useMutation(
        (params: ProductionActionApiEquipmentsCorrectionPutRequest) =>
            equipmentCorrection.put(params).mutationFn(undefined), {
        onSuccess: () => {
            customNotification.success({ message: '재고 보정에 성공 했습니다.' })
            queryClient.invalidateQueries(INVENTORIES_KEY);
            queryClient.invalidateQueries(WORK_LOGS_KEY);
        },
        onError: (error: any) => {
            customNotification.error({ message: error?.response?.data?.message ?? '취소에 실패 했습니다.' })
        }
    })

    const { mutate: cancelMutate, isLoading : cancelLoading } = useMutation(
        (params: ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest) =>
            workLogs.cancel(params).mutationFn(undefined), {
        onSuccess: () => {
            customNotification.success({ message: '취소하였습니다.' })
            queryClient.invalidateQueries(INVENTORIES_KEY);
            queryClient.invalidateQueries(WORK_LOGS_KEY);
        },
        onError: (error: any) => {
            customNotification.error({ message: error?.response?.data?.message ?? '취소에 실패 했습니다.' })
        },
    })

    const isMutateLoading = settingLoading || flushLoading || startLoading || pauseLoading || endLoading || resumeLoading || correctLoading || cancelLoading || defectLoading || performanceLoading;

    return {
        performanceMutate,
        defectMutate,
        settingMutate,
        flushMutate,
        startMutate,
        pauseMutate,
        endMutate,
        resumeMutate,
        correctMutate,
        cancelMutate,
        defectMutateAsync,
        performanceMutateAsync,
        isMutateLoading,
        cancelLoading
    }

}