import { useQuery } from "react-query";

import { AuthSignupPost201Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { MasterInstance } from "instance/axios";

export const WHO_AM_I_KEY = "whoamI";

const fetchAuthWhoamiGet = () => MasterInstance.authWhoamiGet();

export const useAuthWhoamiGet = (options?: { onSuccess: (data: AuthSignupPost201Response) => void }) => {
  return useQuery([WHO_AM_I_KEY], () => fetchAuthWhoamiGet(), {
    select: (data: AxiosResponse<AuthSignupPost201Response, any>) =>
      data.data as AuthSignupPost201Response,
    onSuccess: (data) => {
      options?.onSuccess(data);
    },
    enabled: false
  });
};
